.sidebarContainer {
    height: 100%;
    padding: 5px 5px 5px 5px;
    display: flex !important;
    flex-direction: column !important;
}

.sidebarContainer .ui.segment {
    margin: 5px !important;
    overflow-x: hidden;
    background-color: #151515;
}

/* Search input segment */
.sidebarContainer .ui.segment.searchInputSegment {
    padding: 8px;
    flex-shrink: 0;
    flex-grow: 0;
}

/* ConnectionsTree segment */
.sidebarContainer .ui.segment.demoList {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0;
}


#DemosSegment {
    margin-top:28px;
}

.connectionCount {
    color: #404040;
}

/* topButtonRight inside connectionList segment */
.sidebarContainer .ui.segment.connectionList .topButtonRight {
    float: right;
    margin: 2px 2px 0 0;
}

/* topButtonLeft inside connectionList segment */
.sidebarContainer .ui.segment.connectionList .topButtonLeft {
    float: left;
    margin: 2px -4px 0 2px;
}


/* ticket list main segment */
.sidebarContainer .ui.segment.ticketList {
    padding: 3px 5px 3px 5px;
    min-height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
}

/* internal segments - these are active tickets */
.sidebarContainer .ui.segments.ticketList {
    font-size: 0.9rem;
    padding: 0 5px 0 5px;
    margin-top: -20px;
}

.sidebarContainer .ui.segments.ticketList div.ticketItem:hover {
    background-color: #292929;
}

.ticketSection {
    margin-top: 8px;
}

.ticketSectionHeader {
    color: darkgrey;
}
.sidebarContainer .ui.segments.ticketList div.ticketItem .ticketDetails{
    background-color: #202020;
    margin: 5px 0;
    padding: 5px;
    border: 1px #606060 dashed;
}

.sidebarContainer .ui.segments.ticketList div span:hover.activatable {
    cursor: pointer;
}

.sidebarContainer .ui.accordion .title {
    color: #FFFFFF !important;
}
